hr.negative {
    margin-top: -25px;
    border-width: 2px;
    margin-bottom: 25px;
    @include media-breakpoint-up(md) {
        display: none;
    }
}

.palm {
    height: 50px;
    width: 50px;
    margin: 2rem auto;
    display: block;
    @include media-breakpoint-up(md) {
        position: absolute;
        top: -105px;
        width: 100px;
        height: 100px;
        left: calc(50% - 50px);
    }
}


.attraction {
    text-align: center;

    img {
        height: 45px;
    }
}
