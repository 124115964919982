@include media-breakpoint-up(lg) {
    .nav-relate {
        position: absolute;
    }
}

@keyframes slide {
    0% {
        transform: translateY(-100%)
    }
    100% {
        transform: translateY(0)
    }
}

@keyframes slideOut {
    0% {
        transform: translateY(0)
    }
    100% {
        transform: translateY(-90%)
    }
}

@include media-breakpoint-down(md) {

    .main {

        position: absolute;
        top: 70px;
        left: 0;
        z-index: 0;

        &.hide {
            height: calc(100vh - 70px);
            overflow: hidden;
        }
    }

}

header {
    background-image: url("/assets/img/menu_shape.png");
    width: 100vw;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-color: transparent;

    &:not(.mobile) {
        height: 100vh;
    }

    @include media-breakpoint-down(md) {

        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
    }

    &.mobile {
        background-image: none;
        background-color: $delta;
        display: flex;
        flex-direction: column;
        transition: transform 0.4s ease-in-out;

        &.show {
            animation: slide .5s forwards;
            height: 100vh;

            .book-now {
                text-align: center;
                padding-top: 30px;

                .btn {
                    background-color: transparent !important;
                    color: $white !important;
                    padding-right: 2rem;
                    padding-left: 2rem;
                }
            }
        }

        &.hide {
            animation: slideOut .5s forwards;
            height: 100vh;
        }

        .top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 70px;

            .btn {
                display: block;

                &:active, &:focus, &:focus-visible, &:focus-within {
                    border: none;
                    box-shadow: none;
                }
            }

            .action {
                padding-left: 40px;
                padding-right: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                height: 70px;

                .btn:nth-child(2) {
                    margin-left: 17px;
                }
            }

            .logo {
                padding-left: 40px;
                padding-right: 40px;

            }

            &.show {
                height: auto;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .action {
                    width: 100%;

                    margin-top: 0;
                }

                .logo {
                    border-bottom: 2px solid $gray-500;
                    flex-grow: 1;
                    width: 80%;
                    height: 52px;
                    text-align: center;
                    margin-bottom: 40px;
                }

            }
        }

        .dropdown-menu {
            width: 100%;
            border-radius: 0;
            background-color: $delta;
            border: none;
            opacity: 0;
            display: none;


            &.show {
                display: flex;
                opacity: 1;
                position: static;
                flex-grow: 1;
                flex-basis: 100%;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                padding-bottom: 70px;

                .nav-link {
                    color: $white;
                    text-transform: uppercase;
                }
            }
        }

    }


    .btn.rounded-circle.btn-outline-alpha {
        background-color: transparent !important;
        position: fixed;
        bottom: 30px;
        left: 35%;
        z-index: 101;
        color: $alpha !important;
        transition: all 0.4s ease-in-out .2s;
    }


    .menu {
        transform: translateX(0);
        transition: transform 0.4s ease-out;
        display: flex;
        flex-direction: column;
        height: 100%;

        .rotate {
            color: transparent;
            transform: rotate(270deg);
            font-weight: bold;
            text-align: center;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .translate {
        z-index: 1060;
        position: relative;
        opacity: 100;
        transition: transform 0.4s ease-in-out;
    }

    &.scrolled {
        position: fixed;
        left: 0;
        width: 70px;
        background-image: none;
        animation: ease-in example .51s;
        z-index: 1000;

        .navbar.show {
            background-color: rgba(65, 65, 65, .65);
        }

        @include media-breakpoint-between(sm, xl) {
            width: 50px;
        }

        .btn.rounded-circle.btn-outline-alpha {
            left: 90%;
            transform: rotate(180deg);
            transition: all 0.4s ease-in-out .2s;

        }

        .menu {
            transform: translateY(0);
            transition: all 0.4s ease-out;
            animation: ease-in example .51s;
            left: 0;
            top: 0;
            width: 70px;
            z-index: 1050;

            .rotate {
                color: $body-color;
                border-bottom: 1px solid $body-color;
                margin-top: 5rem;
                position: absolute;
                top: 300px;
                width: 70px;
                transition: all 0.4s ease-in-out .2s;
            }

            &.show {
                width: 150px;
                background-color: rgba(65, 65, 65, .65);
                color: $white;

                .rotate {
                    color: $white;
                    border-color: $white;
                    top: 450px;
                    width: 150px;
                }
            }

            img {
                width: 50px;
                display: block;
                margin: 0 auto;
            }
        }

        .translate, .navbar {
            visibility: hidden;
            transform: translatex(-100%);

            &.show {
                transform: translatex(0);
                visibility: visible;

            }
        }

        .translate.show {
            width: 150px;
        }

    }

    &.show:not(.mobile) {
        transform: translatex(-100%);
    }


    .navbar-nav {

        .nav-link {
            color: $white;
            padding-top: 1rem;
            padding-bottom: 1rem;
            font-size: 23px;
        }

    }

    .navbar {

        transform: translatex(-400%);
        transition: transform 0.4s ease-in-out;
        height: 100%;
        position: absolute;
        top: 0;
        left: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-transform: uppercase;


        &.show {
            transform: translatex(0);
            visibility: visible;
            padding: 30px;
            left: 150px;
        }
    }


}

.vh100 {
    height: 100vh;

    & + .vh100 {
        background-color: gray;
    }
}

@keyframes example {
    from {
        left: -100%;
    }
    to {
        left: 0;
    }
}

hr.small {
    width: 100px;
    height: 2px;
    margin-left: 35px;
    margin-top: 1rem;
}

.text-end, .text-md-end {
    hr.small {
        margin-left: auto;
        margin-right: 35px;
    }
}
