.banner-home, .banner-attractions, .banner-offer, .banner-contact {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.banner-attractions {
    background-image: url(/assets/img/gradzkie-intro.webp);
    height: 300px;
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.banner-offer {
    background-image: url(/assets/img/gradzkie-intro.webp);
    height: 300px;
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.banner-contact {
    background-image: url(/assets/img/gradzkie-intro.webp);
    height: 300px;
    @include media-breakpoint-down(md) {
        display: none;
    }
}


.banner-home {
    background-image: url(/assets/img/gradzkie-intro.webp);
    height: 100vh;
    @include media-breakpoint-up(lg) {
        text-align: right;
    }

    .logo {
        height: 80px;
    }

    .subtitle {
        border-top: 2px solid $alpha;
        margin-right: 0;
        margin-left: auto;
        color: $white;
        font-weight: lighter;
        @include media-breakpoint-down(md) {
            text-align: right;
            padding-top: $pagination-padding-x-sm;
            padding-right: $pagination-padding-x-lg;
            margin-top: $pagination-padding-x-sm;
            margin-left: $pagination-padding-x-lg;
            margin-right: $pagination-padding-x-lg;
        }
    }

    @include media-breakpoint-up(md) {

        .banner-title {
            width: 50%;

            h1 {
                text-align: left;
                margin-bottom: $pagination-padding-x-lg;
            }

            h3 {
                padding-top: $pagination-padding-x;
            }
        }
    }

    .btn.btn-outline-alpha {
        background: transparent !important;
        color: $white !important;
    }

    .overlay {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        height: 100%;
        @include media-breakpoint-down(md) {
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
}

