.card-img-overlay .caption {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
}

.chalet {
    border: 1px solid $black;
    padding: 2rem;

    fa-icon {
        width: 50px;
        min-width: 50px;
    }
}

.carousel.reviews {
    display: flex !important;
    flex-direction: column;
    margin-top: 4rem;

    .carousel-indicators {
        position: static;
        order: 1;
    }

    .carousel-control-prev, .carousel-control-next {
        color: $alpha;

        .carousel-control-prev-icon {
            background-image: none;
            @include fa-icon-solid($fa-var-arrow-left);
            @include fa-size($fa-size-scale-lg);
        }

        .carousel-control-next-icon {
            background-image: none;
            @include fa-icon-solid($fa-var-arrow-right);
            @include fa-size($fa-size-scale-lg);
        }
    }

    .carousel-control-prev {
        left: -40px;
    }

    .carousel-control-next {
        right: -40px;
    }
}
