.icons {
    a {
        padding-right: 1rem;
    }
}

.booking-icon {
    width: 2rem;
    vertical-align: top;
}


.btn.btn-link {
    text-decoration: none;
    font-weight: bold;

    &:hover {
        color: $alpha !important;
    }
}

.btn:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .2), 0 4px 20px 0 rgba(0, 0, 0, .1)
}
