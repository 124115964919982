$fa-font-path: "../webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "mixins";
@import "components/components";
@import "typography";
@import "html";

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
