$alpha: #4aeb64;
$beta: #888;
$gamma: #ff4136;
$delta: #1e3338;
$epsilon: #FFF4F4;
$lambda: #F5F7FA;


$base: #fff;
$primary: #0d6efd;
$danger: #dc3545;

$custom-colors: (
    "alpha": $alpha,
    "beta": $beta,
    "gamma": $gamma,
    "delta": $delta,
    "epsilon": $epsilon,
    "gold": $warning,
);

$light-colors: (
    "lambda": $lambda,
    "base": $base
);
$theme-colors: (
    "primary": $primary,
    "danger": $danger,
    "delta": $delta,
);

$theme-colors: map-merge($theme-colors, $custom-colors);
$spacer: 1rem;

$container-padding-y-xs: $spacer * 1;
$container-padding-y-sm: $spacer * 3;
$container-padding-y: $spacer * 4;
$container-padding-y-lg: $spacer * 5;


$card-border-color: none;
$card-box-shadow: $box-shadow;
$card-border-radius: 7.47px;


$font-family-sans-serif: Poppins, Roboto, sans-serif;
