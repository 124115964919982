@each $color, $value in $theme-colors {
    .bg-#{$color} {
        background-color: $value !important;
    }
    .btn.btn-outline-#{$color} {
        background-color: $white !important;
        border: 2px solid $value !important;
        color: $value !important;
        &:hover, &:active, &:focus{
            background-color: $value !important;
            color: $white !important;
        }
    }
    .btn.btn-#{$color} {
        background-color: $value !important;
        color: $white;
    }
}
