@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-weight: 800;
    src: url('../assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-weight: 300;
    src: url('../assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-weight: 600;
    src: url('../assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-weight: 100;
    src: url('../assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 100;
    src: url('../assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 200;
    src: url('../assets/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 300;
    src: url('../assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: url('../assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: url('../assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: url('../assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 700;
    src: url('../assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 800;
    src: url('../assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 800;
    src: url('../assets/fonts/Poppins/Poppins-Black.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Yeseva';
    font-weight: 500;
    src: url('../assets/fonts/Yeseva/YesevaOne-Regular.ttf') format('truetype');
    font-display: swap;
}

body {
    color: $black;
}

.logo {
    font-family: Yeseva, "Yeseva", sans-serif;
}

h1 {
    font-weight: bold;
    font-size: 80px;
    @include media-breakpoint-down(lg) {
        font-size: 3.125rem;
    }
}

p, h3, h2, li, div, h4, span, a:not(.btn), small {
    font-weight: 200;
}

.pi-2x {
    font-size: 2rem;
}

.pi-3x {
    font-size: 3rem;
}

.pi-4x {
    font-size: 4rem;
}

.pi-5x {
    font-size: 5rem;
}

p:last-child {
    margin-bottom: 0;
}

li{
    &::marker{
        color: $alpha;
    }
}

