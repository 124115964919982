@each $color, $value in $theme-colors {
    .container-#{$color} + .container-#{$color} {
        padding-top: 0;
    }
    .container-#{$color} {
        background-color: $value;
        padding-bottom: $container-padding-y-sm;
        padding-top: $container-padding-y-sm;
        color: $base;
        @include media-breakpoint-up(md) {
            padding-bottom: $container-padding-y;
            padding-top: $container-padding-y;
        }

        &.flush {
            padding-bottom: 0;
        }

        &.flush-top {
            padding-top: 0;
        }

        &.flush-all {
            padding-bottom: 0;
            padding-top: 0;
        }
    }
}

@each $color, $value in $light-colors {
    .container-#{$color} + .container-#{$color} {
        padding-top: 0;
    }
    .container-#{$color} {
        background-color: $value;
        padding-bottom: $container-padding-y-sm;
        padding-top: $container-padding-y-sm;
        color: rgb(12, 12, 12);
        @include media-breakpoint-up(md) {
            padding-bottom: $container-padding-y;
            padding-top: $container-padding-y;
        }

        @include media-breakpoint-down(md) {
            padding-left: 40px;
            padding-right: 40px;
        }

        &.flush {
            padding-bottom: 0;
        }

        &.flush-top {
            padding-top: 0;
        }

        &.flush-all {
            padding-bottom: 0;
            padding-top: 0;
        }

        &.opacity {
            opacity: .96;
        }
    }
}

.deck {
    background-image: url(/assets/img/homepage_bg.webp);
    background-size: cover;
    background-repeat: no-repeat;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    @include media-breakpoint-up(md){
        background-position: center;
    }
}

.spacer {
    height: 200px;
    width: 100%;
    background-color: transparent;
}

@include media-breakpoint-down(md) {

    .border-sm {
        border: 1px solid $black;
    }
}

@include media-breakpoint-up(md) {
    .container.border-alpha-3{
        padding: 5rem;
        border: 1px solid $alpha;
        border-bottom: none;
        position: relative;
        margin-top: 5rem;
    }
}
